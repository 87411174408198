export interface TranslatedString extends Record<string, string> {
  en: string;
  de: string;
}

export interface Category {
  categoryId: string;
  categoryName: TranslatedString;
  children: Application[];
}

export interface Application {
  id: string;
  name: TranslatedString;
  description: TranslatedString;
  url: (environment: string) => string;
  icon: string;
  iconDark?: string;
  intranetOnly: boolean;
}

const defaultEnvironmentUrl =
  (subdomain: string, path?: string) => (environment: string) => {
    if (environment === 'prod') {
      return `https://${subdomain}.devops.telekom.de/${path || ''}`;
    }
    return `https://${subdomain}.${environment}.devops.telekom.de/${path || ''}`;
  };

const applications: Category[] = [
  {
    categoryId: 'magentacicd',
    categoryName: {
      en: 'MagentaCICD',
      de: 'MagentaCICD',
    },
    children: [
      {
        id: 'docs-and-help',
        name: {
          en: 'Documentation & Knowledgebase',
          de: 'Dokumentation & Wissensdatenbank',
        },
        description: {
          en: 'Find documentation and help for MagentaCICD.',
          de: 'Finde Dokumentation und Hilfe für MagentaCICD.',
        },
        url: defaultEnvironmentUrl('docs'),
        icon: '/images/docs-and-help.svg',
        intranetOnly: false,
      },
      {
        id: 'support',
        name: {
          en: 'CICD Solutions Support',
          de: 'CICD Solutions Support',
        },
        description: {
          en: 'Support for CICD Solutions applications.',
          de: 'Supportdienst für CICD Solutions Anwendungen.',
        },
        url: defaultEnvironmentUrl('docs', 'support'),
        icon: '/images/support.svg',
        intranetOnly: false,
      },
      {
        id: 'gitlab',
        name: {
          en: 'GitLab',
          de: 'GitLab',
        },
        description: {
          en: 'Comprehensive DevSecOps Platform.',
          de: 'Umfassende DevSecOps-Plattform.',
        },
        url: defaultEnvironmentUrl('gitlab'),
        icon: '/images/gitlab.svg',
        intranetOnly: false,
      },
      {
        id: 'jfrog',
        name: {
          en: 'JFrog Platform',
          de: 'JFrog Platform',
        },
        description: {
          en: 'Software Supply Chain Platform.',
          de: 'Software-Lieferketten-Plattform.',
        },
        url: defaultEnvironmentUrl('artifactory'),
        icon: '/images/jfrog.svg',
        intranetOnly: false,
      },
      {
        id: 'mtr',
        name: {
          en: 'Magenta Trusted Registry',
          de: 'Magenta Trusted Registry',
        },
        description: {
          en: 'Trusted container registry platform.',
          de: 'Sichere Container-Registry-Plattform.',
        },
        url: defaultEnvironmentUrl('mtr'),
        icon: '/images/mtr.png',
        intranetOnly: false,
      },
      {
        id: 'sonarqube',
        name: {
          en: 'SonarQube',
          de: 'SonarQube',
        },
        description: {
          en: 'Code Quality, Security & Static Analysis tool.',
          de: 'Tool für Codequalität, Sicherheit und statische Analyse.',
        },
        url: defaultEnvironmentUrl('sonarqube'),
        icon: '/images/sonarqube.svg',
        iconDark: '/images/sonarqube-dark.svg',
        intranetOnly: false,
      },
      {
        id: 'yopass',
        name: {
          en: 'Yopass',
          de: 'Yopass',
        },
        description: {
          en: 'Securely share secrets.',
          de: 'Secrets sicher teilen.',
        },
        url: defaultEnvironmentUrl('secret-share'),
        icon: '/images/yopass.svg',
        intranetOnly: true,
      },
      {
        id: 'plantuml',
        name: {
          en: 'PlantUML',
          de: 'PlantUML',
        },
        description: {
          en: 'Textual UML diagram creation tool.',
          de: 'Tool zur textuellen Erstellung von UML-Diagrammen.',
        },
        url: defaultEnvironmentUrl('plantuml'),
        icon: '/images/plantuml.svg',
        intranetOnly: false,
      },
      {
        id: 'shields',
        name: {
          en: 'Shields.io',
          de: 'Shields.io',
        },
        description: {
          en: 'Badge creation tool.',
          de: 'Tool zur Erstellung von Badges.',
        },
        url: defaultEnvironmentUrl('shields'),
        icon: '/images/shields.svg',
        iconDark: '/images/shields-dark.svg',
        intranetOnly: true,
      },
      {
        id: 'code-factory',
        name: {
          en: 'Code Factory',
          de: 'Code-Factory',
        },
        description: {
          en: 'A "starter-kit" generator for your new applications or APIs.',
          de: 'Ein "Starter-Kit-Generator" für Ihre neuen Anwendungen oder APIs..',
        },
        url: defaultEnvironmentUrl('code-factory'),
        icon: '/images/code-factory.png',
        intranetOnly: true,
      },
    ],
  },
  {
    categoryId: 'more',
    categoryName: {
      en: 'More applications',
      de: 'Weitere Anwendungen',
    },
    children: [
      {
        id: 'jira',
        name: {
          en: 'Telekom Jira',
          de: 'Telekom Jira',
        },
        description: {
          en: 'Issue tracking and project management tool.',
          de: 'Tool zur Verfolgung von Problemen und Projektmanagement.',
        },
        url: () => 'https://jira.telekom.de',
        icon: '/images/jira.svg',
        intranetOnly: false,
      },
      {
        id: 'wiki',
        name: {
          en: 'Telekom Wiki',
          de: 'Telekom Wiki',
        },
        description: {
          en: 'Issue tracking and project management tool.',
          de: 'Tool zur Verfolgung von Problemen und Projektmanagement.',
        },
        url: () => 'https://wiki.telekom.de',
        icon: '/images/wiki.svg',
        iconDark: '/images/wiki-dark.svg',
        intranetOnly: false,
      },
      {
        id: 'yam-digihub',
        name: {
          en: 'Digital Hub CI/CD @ YAMU',
          de: 'Digital Hub CI/CD @ YAMU',
        },
        description: {
          en: 'News and information about the CI/CD Hub portfolio.',
          de: 'Neuigkeiten und Informationen zum CI/CD-Hub-Portfolio',
        },
        url: () => 'https://yam-united.telekom.com/pages/digital-hub-ci-cd/',
        icon: '/images/yam.svg',
        intranetOnly: true,
      },
      {
        id: 'yam-devops-community',
        name: {
          en: 'DevOps Community @ YAMU',
          de: 'DevOps Community @ YAMU',
        },
        description: {
          en: 'Join the discussion in the DevOps Community.',
          de: 'Diskutiere in der DevOps-Community.',
        },
        url: () =>
          'https://yam-united.telekom.com/workspaces/devops-community/',
        icon: '/images/yam.svg',
        intranetOnly: true,
      },
    ],
  },
];

export default applications;

import MainLayout from '@/components/MainLayout';
import applications from '@/helpers/applications';
import { CommonProps, commonServerSideProps } from '@/helpers/props';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

export default function Home({ isIntranet, featureFlags }: CommonProps) {
  const { i18n } = useTranslation('common');
  return (
    <MainLayout featureFlags={featureFlags}>
      {featureFlags.homepage &&
        applications.map((category) => (
          <div key={category.categoryId} className="mb-12">
            <h2 className="divider divider-start text-3xl">
              {category.categoryName[i18n.language] || category.categoryId}
            </h2>
            <div className="grid md:justify-items-center 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
              {category.children.map((app) => {
                const appTitle = app.name[i18n.language] || app.id;
                const appDescription = app.description[i18n.language] || app.id;
                return (
                  (isIntranet || !app.intranetOnly) && (
                    <a
                      key={app.id}
                      className="card bg-base-100 dark:bg-base-200 md:w-64 shadow-lg hover:shadow-xl hover:z-20 focus:shadow-xl focus:z-20 transition-shadow"
                      href={app.url(featureFlags.environment)}
                      target="_blank"
                      rel="noopener"
                    >
                      <figure>
                        <Image
                          src={app.icon}
                          alt={`${appTitle} Logo`}
                          width={128}
                          height={128}
                          loading="eager"
                          className="m-4 object-contain md:aspect-square w-32 block dark:hidden"
                        />
                        <Image
                          src={app.iconDark || app.icon}
                          alt={`${appTitle} Logo`}
                          width={128}
                          height={128}
                          loading="eager"
                          className="m-4 object-contain md:aspect-square w-32 hidden dark:block"
                        />
                      </figure>
                      <div className="card-body flex-grow">
                        <h2 className="card-title">{appTitle}</h2>
                        <p>{appDescription}</p>
                      </div>
                    </a>
                  )
                );
              })}
            </div>
          </div>
        ))}
      {!featureFlags.homepage && (
        <h1 className="text-3xl text-center">MIS Portal</h1>
      )}
    </MainLayout>
  );
}

export { commonServerSideProps as getServerSideProps };

import getConfig from 'next/config';

const Footer = () => {
  const { publicRuntimeConfig } = getConfig();

  return (
    <footer className="footer footer-center p-10 bg-telekom-gray text-gray-300">
      <p className="text-lg">Deutsche Telekom IT GmbH</p>
      <nav className="grid grid-flow-col gap-4">
        <a href="https://docs.devops.telekom.de/support" className="link">
          Help &amp; Service
        </a>
        <a href="https://devops.telekom.de/en/privacy" className="link">
          Data Privacy
        </a>
        <a href="https://devops.telekom.de/en/terms" className="link">
          Terms of Use
        </a>
      </nav>
      <aside>
        <p>Version {publicRuntimeConfig.appVersion}</p>
      </aside>
    </footer>
  );
};

export default Footer;
